


const DefaultOpt = {
    ais: '_auto_init_session',
    _inner_init: true,
    key: 'klk_i_sn',
    renew: false,
    cacheTime: 30 * 60 * 1000
}
const IhtDefaultOpt = {
    ...DefaultOpt,
    type: 0,
    ais: '_auto_init_session',
    key: 'klk_i_sn',
    gaKey: 'iht_fe_session_id',
}
const GaDefaultOpt = {
    ...DefaultOpt,
    type: 1,
    force: false,
    ais: '_ga_auto_init_session',
    key: 'klk_ga_sn',
    gaKey: 'fe_session_id'
}

export const SessionChange = 'sessionchange'

export {
    DefaultOpt , IhtDefaultOpt, GaDefaultOpt
}
