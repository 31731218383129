
export function detectSpm(fc) {
    // @ts-ignore
    if (!fc && detectSpm.ok) return
    (function dd(a, b, c) {
    try {
        var sp = b.querySelector('[' + c.p + ']'); var dl = a[c.d] || [];
        // @ts-ignore 
        sp && (sp = sp.dataset.spmPage || '') && (sp = sp.split('?')[0]) && (dl.push({ spm: sp })) && (a[c.d] = dl) && (detectSpm.ok=true);
    } catch (e) { c.c.info(e) } })(window, document, { d: 'dataLayer', c: console, p: 'data-spm-page' });
}