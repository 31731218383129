import jsCookie from "js-cookie"
import { PushStateChange, ReplaceStateChange, ael } from './event'
import { tryCatch } from './util'
import { DefaultOpt, GaDefaultOpt, IhtDefaultOpt, SessionChange } from './constant'

let session_data: any = {}

const SeDefaultOpt = {
  renew: true
}

const state = {}

const current = () => Date.now()

const generateSessionId = () => {
  return Math.random().toString().slice(-10)
}

const getSessionInfoFromCache = (key: string, type = 1) => {
  const info = type === 1 ? jsCookie.get(key) : tryCatch(() => localStorage.getItem(key))
  const data = info ? info.split('..') : ['', '']
  return { session_id: data[0], iht_last_event_time: parseInt(data[1]) }
}
const getSessionInfo = (key: string) => {
  const info = getSessionInfoFromCache(key) 
  return isValid(info) ? info : getSessionInfoFromCache(key, 2)
}
const setSessionInfo = (key: string, value: Record<string, any>) => {
  const data = [value.session_id, value.iht_last_event_time].join('..')
  jsCookie.set(key, data, { expires: new Date(current() + DefaultOpt.cacheTime)})
  tryCatch(() => localStorage.setItem(key, data))
}

const isValid = (info: Record<string, any> = {}) => {
  if (!info) return false
  const lastTime = parseInt(info.iht_last_event_time || 0)
  const timeSlice = current() - lastTime
  return info.session_id && timeSlice && (timeSlice <= DefaultOpt.cacheTime)
}

const emitSessionChange = (sessionInfo, type) => {
  const ev = new Event(SessionChange) as Event & { args: any, changetype: number };
  ev.args = sessionInfo
  ev.changetype = type
  window.dispatchEvent(ev)
}

const commonInitSession = (opt: Record<string, any>) => {
  const key = opt.key, renew = opt.renew;
  try {
    let dataInfo = getSessionInfo(key)
    const currentTime = current()
    if (!isValid(dataInfo)) {
      dataInfo = {
        iht_last_event_time: currentTime,
        session_id: generateSessionId()
      }
      setSessionInfo(key, dataInfo)
      emitSessionChange(dataInfo, opt.type)
    } else {
      if (renew) {
        const preTime = dataInfo.iht_last_event_time
        dataInfo.iht_last_event_time = currentTime
        if (currentTime - preTime > 1000 * 60) {
          setSessionInfo(key, dataInfo)
        } else {
          if (!state[key]) {
            state[key] = true
            setSessionInfo(key, dataInfo)
            setTimeout(() => {state[key] = false}, 3 * 1000)
          }
        }
        emitSessionChange(dataInfo, opt.type)
      }
    }
    return dataInfo
  } catch(e) {}
}

const initIhtSession = (option: Record<string, any> = {}) => {
  const opt = Object.assign({}, IhtDefaultOpt, option)
  if (window[DefaultOpt.ais] === false) return;
  const dataInfo = commonInitSession(opt)
  session_data.iht = dataInfo
  return dataInfo
}

const initGaSession = (option: Record<string, any> = {}) => {
  const opt = Object.assign({}, GaDefaultOpt, option)
  if (window[GaDefaultOpt.ais] === false) return;
  const dataInfo = commonInitSession(opt)
  session_data.ga = dataInfo
  return dataInfo
}


const updateIhtSession = (opt = {}) => {
  return initIhtSession({ ...SeDefaultOpt, ...opt })
}

const updateGaSession = (opt = {}) => {
  return initGaSession({ ...SeDefaultOpt, ...opt })
}

const getGaSession = (opt: Record<string, any> = {}) => {
  const gaSession = session_data.ga
  if (!opt.force && gaSession && gaSession.session_id) return gaSession
  return initGaSession({ ...SeDefaultOpt, ...opt })
}

const initSession = () => {
  updateIhtSession(SeDefaultOpt)
  updateGaSession(SeDefaultOpt)
}

const historyChangeEvent = () => {
  ael(PushStateChange, () => updateGaSession())
  ael(ReplaceStateChange, () => updateGaSession())
}

const getCurrentSessionInfo = () => {
  return {
    ga: getGaSession(), 
    iht: initIhtSession()
  }
}
const getCurrentSession = () => {
  return {
    [GaDefaultOpt.gaKey]: (getGaSession() || {}).session_id, 
    [IhtDefaultOpt.gaKey]: (initIhtSession() || {}).session_id
  }
}

export {
  getCurrentSession,
  historyChangeEvent,
  initGaSession,
  getGaSession,
  initIhtSession,
  updateIhtSession,
  updateGaSession,
  GaDefaultOpt,
  IhtDefaultOpt,
  DefaultOpt,
  isValid,
  getSessionInfo,
  getCurrentSessionInfo,
  generateSessionId,
  setSessionInfo,
  initSession
}