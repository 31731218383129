import { tryCatch } from './util'
import { initHistoryChange } from "./event"
import { historyChangeEvent, initSession } from "./session"
import { GaDefaultOpt, IhtDefaultOpt, SessionChange } from './constant'

declare global {
    interface Window {
        dataLayer: any[]
    }
}
const initSessionChange = () => {
    const dl = window.dataLayer || []
    window.dataLayer = dl
    dl.push({site_name: location.host, user_agent: navigator.userAgent})
    const lastSession = {}
    window.addEventListener(SessionChange, (ev) => {
        const event = ev as Event & { args: any, changetype: number };
        const args = event.args
        const key = event.changetype == GaDefaultOpt.type ? GaDefaultOpt.gaKey : IhtDefaultOpt.gaKey
        const session_id = args && args.session_id;
        if (session_id && lastSession[key] !== session_id) {
            window.dataLayer.push({
                [key]: session_id,
            })
            lastSession[key] = session_id
        }
    })
}

tryCatch(() => {
    initSessionChange()
    initSession()
    initHistoryChange()
    historyChangeEvent()
})