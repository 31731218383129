import { tryCatch } from './util'
export const ael = window.addEventListener.bind(window)
let flag = false;

const emit = (type, args) => {
    const ev = new Event(type) as Event & { args: any[]};
    ev.args = args;
    window.dispatchEvent(ev);
}

export const PushStateChange = 'PushStateChange'
export const ReplaceStateChange = 'ReplaceStateChange'
export const PopStateChange = 'PopStateChange'

export const initHistoryChange = () => {
    if (flag) return
    flag = true;
    const pushState = history.pushState, replaceState = history.replaceState;

    history.pushState = function(...args) {
        tryCatch(() => emit(PushStateChange, args))
        return pushState.call(history, ...args);
    }
    history.replaceState = function(...args) {
        tryCatch(() => emit(ReplaceStateChange, args))
        return replaceState.call(history, ...args);
    }
    ael("popstate", (t) => {
        emit(PopStateChange, [t])
    })
}
